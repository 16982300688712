// extracted by mini-css-extract-plugin
export var authorSocials = "blog-post-module--author-socials--a1dd2";
export var beadcrumbItem = "blog-post-module--beadcrumb-item--5e1fd";
export var beadcrumbs = "blog-post-module--beadcrumbs--e3742";
export var heading = "blog-post-module--heading--de219";
export var postAuthorImg = "blog-post-module--post-author-img--a9122";
export var postAuthorName = "blog-post-module--post-author-name--1dead";
export var postAuthorWrapper = "blog-post-module--post-author-wrapper--12e91";
export var postContent = "blog-post-module--post-content--88f0a";
export var postDate = "blog-post-module--post-date--43468";
export var postFooter = "blog-post-module--post-footer--6118e";
export var postLine = "blog-post-module--post-line--4f57d";
export var shareBlock = "blog-post-module--share-block--4b554";
export var singleNavigation = "blog-post-module--single-navigation--07800";
export var socials = "blog-post-module--socials--8f6e2";
export var tagsWrapper = "blog-post-module--tags-wrapper--71412";